<template>
  <div class="pageHomeViewByCourseNormal__root">
    <div :id="`accordion-${category.id}`" class="pageHomeViewByCourseNormal" v-for="(category, k) in getAllCategories" :key="category.id">
      <div class="pageHomeViewByCourseNormal__header">
        <CommonTitleCourse :value="category.title" />
      </div>

      <ManageCategory :course_id="category.id" />

      <div class="pageHomeViewByCourseNormal__container" :class="{ mobile: getIsMobile }">
        <template v-if="loading || general.length === 0">
          <div class="pageHomeViewByCourseNormal__container--box" v-for="i in 8" :key="i">
            <CommonSkeletonsCover />
          </div>
        </template>

        <template v-else>
          <template v-for="(values, index) in general">
            <draggable :animation="200" v-model="values[category.id]" :key="index" ghost-class="sortable-ghost"
              :disabled="!getRearrangeCourse" class="pageHomeViewByCourseNormal__container--drag"
              @end="saveRearangeOrderCourse">
              <div class="pageHomeViewByCourseNormal__container--box" v-for="item in values[category.id]"
                :key="item.order" :class="{ active: getRearrangeCourse }">
                <div class="pageHomeViewByCourseNormal__container--box-item">
                  <CommonCardsCourse v-if="item.cover" :data="item" @show="$router.push({ path: 'curso/' + item.id })"
                    @edit="$router.push({ path: 'config_curso/' + item.id })" />

                  <EmptyStateCourseCover v-else @edit="$router.push({ path: 'config_curso/' + item.id })" />

                  <CommonBoxCourse :data="item" />
                </div>
                <div v-if="getRearrangeCourse" class="pageHomeViewByCourseNormal__container--overlay"
                  @dragstart="dragStart" @dragend="dragEnd"></div>
              </div>
            </draggable>
          </template>
        </template>
      </div>
    </div>

    <infinite-loading @infinite="infiniteHandler" v-if="courses.length > 0">
      <div slot="no-results"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
      <div slot="error"></div>
    </infinite-loading>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import draggable from "vuedraggable";
import InfiniteLoading from "vue-infinite-loading";
import { NO_CATEGORY } from "@/utils/const";
import EmptyStateCourseCover from "@/components/common/cards/cover/EmptyStateCourseCover.vue";
import ManageCategory from "@/components/common/cards/cover/Category.vue";


export default {
  name: "CategoryView",
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      general: [],
      page: 1,
      loadingMore: false,
      firstCall: true,
      heightContainer: 0,
      emptyStateCourse: false
    };
  },
  computed: {
    ...mapGetters({
      getModeCarousel: "config/getModeCarousel",
      getMovieCoverMode: "config/getMovieCoverMode",
      getRearrangeCourse: "home/getRearrangeCourse",
      getCoursesList: "home/getCoursesList",
      getAllCategories: "home/getAllCategories",
      getIsMobile: "layout/getIsMobile",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getEmptyState: "home/getEmptyState"
    }),
    courses: {
      get() {
        if (this.getHideDisabledCourses) {
          return this.getCoursesList.filter((item) => item?.has_access);
        } else {
          return this.getCoursesList;
        }
      },
      set(value) {
        this.actionUpdateCoursesList(value);
      },
    },
  },
  components: {
    draggable,
    CommonTitleCourse: require("@/components/common/title/Course.vue").default,
    CommonBoxCourse: require("@/components/common/box/Course.vue").default,
    CommonCardsCourse: require("@/components/common/cards/Course.vue").default,
    CommonSkeletonsCover: require("@/components/common/skeletons/Cover.vue")
      .default,
    InfiniteLoading,
    EmptyStateCourseCover,
    ManageCategory
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions({
      actionUpdateCoursesList: "home/actionUpdateCoursesList",
      actionFetchHomeData: "home/actionFetchHomeData",
      actionToggleRearrangeCourse: "home/actionToggleRearrangeCourse",
      actionRearrangeOrderCourse: "home/actionRearrangeOrderCourse",
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
    }),
    async loadData(params) {
      await this.actionFetchHomeData(params);

      if(this.getEmptyState){
        this.emptyStateCourse = this.getEmptyState.empty_state_course;
      }

      const courses = [...this.courses];
      const dataCourses = this.organizedData(courses);
      this.general = [dataCourses];
    },
    dragStart(e) {
      setTimeout(() => {
        e.target.parentNode.classList.add("grabbing");
      }, 50);
    },
    dragEnd(e) {
      e.target.parentNode.classList.remove("grabbing");
    },

    organizedData(values) {
      return values.reduce((acc, item) => {
        const category = item.category || NO_CATEGORY

        const categoryId = category?.id;
        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }
        acc[categoryId].push(item);
        return acc;
      }, {});
    },

    coursesByCategory(category) {
      return this.courses.filter((item) => item?.category?.id === category);
    },

    async infiniteHandler($state) {
      if (this.page === this.lastPage) {
        $state.complete();
        return;
      }
      this.page += 1;
      try {
        await this.loadData({ page: this.page })
        $state.loaded();
      } catch (error) {
        $state.error();
      }
    },

    async saveRearangeOrderCourse() {

      const coursesOrganizedById = [];

      this.general.forEach((item) => {
        Object.keys(item).forEach((key) => {
          const value = item[key];
          coursesOrganizedById.push(value);
        });
      });

      const onlyIDs = coursesOrganizedById.flatMap((item) => item).map(item => item.id);

      await this.actionRearrangeOrderCourse(onlyIDs);

      await this.loadData();

      await this.actionToggleRearrangeCourse();
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
      default: () => [{}],
    },
    widthCard: {
      type: String,
      default: "264px",
    },
    showRating: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>

.loading-more {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.sortable {
  &-ghost {
    border-radius: 15px;
 
    background: #dfdfdf;
    border: 5px dashed #919191;
    padding: 20px;
    cursor: grab;
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &-chosen {
    opacity: 0.8;
  }
}

.pageHomeViewByCourseNormal__container--drag {
  display: contents;

  div {
    cursor: grab;
  }
}


.pageHomeViewByCourseNormal {
  margin-bottom: 64px;

  &__header {
    @include flex-row;
    width: 100%;
  }

  &__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: var(--spacing-16);

    &.mobile {
      gap: 20px;
    }

    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: grab !important;
    }

    &--drag {
      display: contents;

      div {
        cursor: grab;
      }
    }

    &--box {
      max-width: v-bind("widthCard");
      flex-basis: calc(100% / 3);
      box-sizing: border-box;

      &.active {
        animation: pulseDrag 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
      }

      @media screen and (max-width: 820px) {
        flex-basis: calc((100% / 3) - 16px);
      }

      @media screen and (max-width: 768px) {
        flex-basis: calc((100% / 3) - 16px);
      }

      @media screen and (max-width: 480px) {
        flex-basis: calc((100% / 2) - 12px);
      }

      @media screen and (max-width: 360px) {
        flex-basis: calc((100% / 2) - 12px);

        ::v-deep .commonCardsCover__box {
          min-width: 151px;
        }
      }

      @media screen and (max-width: 310px) {
        flex-basis: 100%;
      }
    }
  }
}
</style>
